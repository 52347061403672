<template>
    <div>
        <v-header showStyle="white"></v-header>
        <div class="bg-box">
            <div class="wrap">

                <!-- 公共信息区 -->
                <div class="user-head f-row-b-c" style="">
                    <div class="l-info f-row-s-c">
                        <img class="imgs" :src="info.businessLicense || require('../../assets/images/avatar.png')" />
                        <div class="infos">
                            <p class="names">{{ info.enterpriseName || $t('N6') }}</p>
                            <p class="txts">
                                <span>{{ $t('my1') }}：</span>
                                {{ info.businessLicenseNo }}
                            </p>
                            <p class="txts">
                                <span>{{ $t('my2') }}：</span>
                                {{ info.enterpriseAddress }}{{ info.detailAddress }}
                            </p>
                        </div>
                    </div>
                    <div class="r-btn">
                        <button @click="onLink">{{ $t('my7') }}</button>
                    </div>
                </div>

                <div class="f-row-c-s">
                    <v-sidebar></v-sidebar>
                    <div style="width:955px;">
                        <router-view></router-view>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'center',
    data() {
        return {
            info: {}
        }
    },
    created() {
        this.getData()
    },
    methods: {
        //获取企业信息
        getData() {
            $api.getEnterpriseUserExtInfo().then(res => {
                if (res.code == 200) {
                    this.info = res.data || {}
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //专属链接
        onLink() {
            if (this.$route.path != '/centre/link') this.$router.push('/centre/link')
        }
    }
}
</script>

<style lang="scss" scoped>
.user-head {
    margin-bottom: 20px;
    padding: 0 30px;
    width: 100%;
    height: 180px;
    background: url('../../assets/images/grzy_bg_t.png') no-repeat center center/cover;

    .l-info {
        gap: 20px;

        .imgs {
            position: relative;
            width: 110px;
            height: 110px;
            background: #ddd;
            border-radius: 50%;
            overflow: hidden;
        }

        .infos {
            gap: 10px;

            .names {
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 20px;
                color: #2E2D2D;
            }

            .txts {
                line-height: 28px;
                font-size: 15px;
                color: #333;

                span {
                    color: #727272;
                }
            }
        }
    }

    .r-btn {
        button {
            width: 132px;
            height: 44px;
            font-size: 20px;
            color: #FFFFFF;
            background: var(--theme-color);
            border-radius: 5px;
            cursor: pointer;
            user-select: none;
        }
    }
}
</style>