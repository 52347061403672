<template>
    <div>
        <v-header showStyle="white"></v-header>

        <div class="bg-box">
            <div class="wrap contain-box loading-box">

                <!-- 行程信息 -->
                <div class="card-box">
                    <v-title style="margin-top: 0;">{{ $t('M29') }}</v-title>
                    <div class="distance-box cover-box">
                        <div class="cards text-box f-row-s-c">
                            <div class="card-imgs f-row-c-c">
                                <img src="../../assets/images/index_icon_place.png" alt="">
                            </div>
                            <span>{{ $t('M30') }}：</span>
                            <div class="one-txt-cut">{{ orders.setOutAddress || '' }}</div>
                        </div>
                        <div v-if="curOrderType == 0" class="cards text-box f-row-s-c">
                            <div class="card-imgs f-row-c-c">
                                <img src="../../assets/images/index_icon_place.png" alt="">
                            </div>
                            <span>{{ $t('M31') }}：</span>
                            <div class="one-txt-cut">{{ orders.arriveOutAddress || '' }}</div>
                        </div>
                        <div class="f-row-s-c">
                            <div class="cards text-box f-row-s-c" style="margin-right: 40px;">
                                <div class="card-imgs f-row-c-c">
                                    <img src="../../assets/images/index_icon_date.png" alt="">
                                </div>
                                <span>{{ $t('M32') }}：</span>
                                <div class="">{{ orders.appointmentTime || '' }}</div>
                            </div>
                            <div v-if="curOrderType == 0" class="cards text-box f-row-s-c" style="margin-right: 40px;">
                                <div class="card-imgs f-row-c-c">
                                    <img src="../../assets/images/four_icon_juli.png" alt="">
                                </div>
                                <span>{{ $t('M33') }}：</span>
                                <div class="">{{ orders.travelDistance ? orders.travelDistance + 'km' : '' }}</div>
                            </div>
                            <div v-if="curOrderType == 1" class="cards text-box f-row-s-c" style="margin-right: 40px;">
                                <div class="card-imgs f-row-c-c">
                                    <img src="../../assets/images/index_icon_time.png" alt="">
                                </div>
                                <span>{{ $t('M35') }}：</span>
                                <div class="">{{ orders.vehicleDuration || '' }}</div>
                            </div>
                            <div class="cards text-box f-row-s-c">
                                <div class="card-imgs f-row-c-c">
                                    <img src="../../assets/images/index_icon_renshu.png" alt="">
                                </div>
                                <span>{{ $t('M34') }}：</span>
                                <div class="">{{ orders.numberOfPassengers || '' }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 车辆信息 -->
                <div class="card-box">
                    <v-title>{{ $t('info1') }}</v-title>
                    <div class="car-list cover-box f-col" style="gap: 20px;">
                        <div class="car-item" v-for="item in carList" :key='item.id'>
                            <v-car :item="item"></v-car>
                        </div>
                    </div>
                </div>

                <!-- 平台服务 -->
                <div class="card-box">
                    <v-title>{{ $t('info6') }}</v-title>
                    <div class="serve-box cover-box f-row-s-c">
                        <div class="items text-box f-row-s-c" v-for="(item, index) in serveList" :key="index">
                            <div class="card-imgs f-row-c-c">
                                <img v-if="item.serviceName == $t('info7')" src="../../assets/images/clxx_icon_jpjj.png" />
                                <img v-if="item.serviceName == $t('info8')" src="../../assets/images/clxx_icon_ety.png" />
                                <img v-if="item.serviceName != $t('info8') && item.serviceName != $t('info7')"
                                    src="../../assets/images/clxx_icon_qtfw.png" />
                            </div>
                            <div>
                                <span>{{ item.serviceName }}</span>
                                <span v-if="item.serviceName == $t('info8')">×{{ item.num }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 负责人信息 -->
                <div class="card-box">
                    <v-title>{{ $t('N24') }}</v-title>
                    <div class="person-box cover-box">
                        <template v-if="Object.keys(personInfo).length > 0">
                            <div class="names f-row-s-c" @click="handlePerson(personInfo)">
                                {{ personInfo.directorSurname }}{{ personInfo.directorName }}
                                <img src="../../assets/images/xfzr_icon_edit.png"
                                    style="margin-left:10px;width:18px;height:20px;" alt="">
                            </div>
                            <div class="f-row-s-c">
                                <div v-if="personInfo.directorMobile" class="items f-row-s-c">
                                    <div class="card-imgs f-row-c-c">
                                        <img src="../../assets/images/xfzr_icon_dh.png" alt="">
                                    </div>
                                    <div>{{ areaCode }}{{ personInfo.directorMobile }}</div>
                                </div>
                                <div v-if="personInfo.directorEmail" class="items f-row-s-c">
                                    <div class="card-imgs f-row-c-c">
                                        <img src="../../assets/images/xfzr_icon_yx.png" alt="">
                                    </div>
                                    <div>{{ personInfo.directorEmail }}</div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-box f-row-s-c" style="cursor: pointer;" @click="handlePerson()">
                                <img src="../../assets/images/four_icon_add.png"
                                    style="margin-right:10px;width:24px;height:24px;" alt="">
                                {{ $t('I38') + $t('N24') }}
                            </div>
                        </template>
                    </div>
                </div>

                <!-- 航班信息 -->
                <div class="card-box">
                    <v-title>{{ $t('info10') }}</v-title>
                    <div class="inputs cover-box" style="width:296px;">
                        <el-input v-model="form.airplaneNo" :placeholder="$t('I16') + $t('info11')" clearable></el-input>
                    </div>
                </div>

                <!-- 订单留言 -->
                <div class="card-box">
                    <v-title>{{ $t('info19') }}</v-title>
                    <div class="inputs cover-box" style="width:786px;">
                        <el-input v-model="form.orderMessage" :placeholder="$t('I16') + $t('info19')" clearable></el-input>
                    </div>
                </div>

                <!-- 官方邮件 -->
                <div class="card-box">
                    <v-title>{{ $t('info20') }}</v-title>
                    <div class="cover-box f-row-s-c">
                        <div class="text-box" style="margin-right:60px;">{{ $t('M37') }}</div>
                        <el-radio-group v-model="form.isEmail">
                            <el-radio :label="0">{{ $t('M39') }}</el-radio>
                            <el-radio :label="1">{{ $t('M38') }}</el-radio>
                        </el-radio-group>
                    </div>
                </div>

                <!-- 支付方式 -->
                <div class="card-box">
                    <v-title>{{ $t('info21') }}</v-title>
                    <div class="cover-box">
                        <el-radio-group v-model="form.payWay">
                            <el-radio label="1">{{ $t('M40') }}</el-radio>
                            <el-radio label="2">{{ $t('M81') }}</el-radio>
                            <el-radio label="3">{{ $t('M41') }}</el-radio>
                        </el-radio-group>
                    </div>
                </div>

                <!-- 信用卡信息 -->
                <div v-if="form.payWay == 3" class="card-box">
                    <v-title>{{ $t('N25') }}</v-title>
                    <div class="bank-box cover-box f-row-s-c f-w" style="gap:20px;width: 806px;">
                        <div class="inputs" style="width:344px;">
                            <el-input v-model="form.bankName" :placeholder="$t('I16') + $t('I35')" clearable></el-input>
                        </div>
                        <div class="inputs" style="width:420px;">
                            <el-input v-model="form.bankCode" :placeholder="$t('I16') + $t('I29')" clearable></el-input>
                        </div>
                        <div class="inputs" style="width:174px;">
                            <el-input v-model="form.bankSafeCode" :placeholder="$t('I16') + $t('M41') + $t('I36')"
                                clearable></el-input>
                        </div>
                        <div class="inputs" style="width:278px;">
                            <el-input v-model="form.bankTime" :placeholder="$t('I16') + $t('M41') + $t('I37')"
                                clearable></el-input>
                        </div>
                    </div>
                </div>

                <!-- 底部操作 -->
                <div class="common-btn f-row-c-c" style="margin-top:20px;">
                    <div class="btn" @click="handleSubmit">{{ $t('N26') }}</div>
                </div>

            </div>
        </div>

        <!-- 负责人弹窗 -->
        <el-dialog :visible.sync="isShow" width="674px" :show-close="false" @close="isShow = false">
            <div class="dialog-top f-row-b-c" slot="title">
                <span class="titles">{{ $t('N24') }}</span>
                <i class="closes el-icon-close" @click="isShow = false"></i>
            </div>
            <div class="dialog-con f-row-c-s">
                <div class="con-title">{{ $t('N27') }}：</div>
                <div class="f-row-s-c f-w" style="gap:20px;">
                    <div class="f-inputs" style="width:210px;">
                        <el-input v-model="personForm.directorSurname" :placeholder="$t('I16') + $t('N27') + $t('N28')"
                            clearable></el-input>
                    </div>
                    <div class="f-inputs" style="width:210px;">
                        <el-input v-model="personForm.directorName" :placeholder="$t('I16') + $t('N27') + $t('N29')"
                            clearable></el-input>
                    </div>
                    <div class="f-inputs" style="width:440px;">
                        <el-input v-model="personForm.directorEmail" :placeholder="$t('I16') + $t('N27') + $t('I10')"
                            clearable></el-input>
                    </div>
                    <div class="select-box f-row-s-c">
                        <el-select class="area-select" v-model="areaCode" :placeholder="$t('N11')">
                            <el-option v-for="item in areaList" :key="item.id" :label="'+' + item.areaCode"
                                :value="item.areaCode">
                            </el-option>
                        </el-select>
                        <el-input v-model="personForm.directorMobile"
                            :placeholder="$t('I16') + $t('N27') + $t('info31')"></el-input>
                    </div>
                </div>
            </div>
            <div class="dialog-foot f-row-c-c" slot="footer">
                <el-button type="primary" @click="confirmPerson">{{ $t('I38') }}</el-button>
            </div>
        </el-dialog>

        <!-- 支付二维码弹窗 -->
        <vpay-dialog ref="payChild" :isShow="payDialog" :infos="codeInfo" :payWay="form.payWay"
            @closeDialog="closePayDialog"></vpay-dialog>

    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'confirm',
    data() {
        return {
            curOrderType: 0, //0专车 1包车
            orders: {},//订单数据
            carList: [],//车型数据
            copyCarList: [],//copy车型数据
            serveList: [],//服务数据
            personInfo: {},//负责人数据

            form: {
                airplaneNo: '',//航班号
                orderMessage: '',//订单留言
                isEmail: 1,//官方邮件 0否 1是
                payWay: '1',//支付方式 1支付宝 2微信 3信用卡
                bankName: '',//所属银行
                bankCode: '',//信用卡号
                bankSafeCode: '',//信用卡安全码
                bankTime: '',//信用到期时间
            },
            theRaising: 1,//接机服务（1不需要 2需要）
            theBaby: 0,//儿童座椅数量
            otherServiceIds: [],//其他服务id

            //弹窗--负责人
            isShow: false,//显示弹窗
            areaList: [],//手机区号列表
            areaCode: '+86',//手机区号
            personForm: {
                directorSurname: '',//姓
                directorName: '',//名
                directorEmail: '',//邮箱
                directorMobile: '',//手机号
            },

            //弹窗--扫码支付
            payDialog: false,//显示弹窗
            codeInfo: {
                id: '',
                urlCode: '',
            },
        }
    },
    computed: {
        disabledBtn() {
            for (let key in this.personForm) {
                if (this.personForm[key] === '') {
                    return true;
                }
            }
            return false;
        },
    },
    created() {
        this.getPhoneAreaList()
        this.curOrderType = this.$route.query.type
        //行程数据
        if (localStorage.getItem('orderInfo')) {
            this.orders = JSON.parse(decodeURIComponent(localStorage.getItem('orderInfo')))
            console.log('orderInfo', this.orders)
        }
        //车辆数据
        if (localStorage.getItem('selectCarInfo')) {
            this.carList = JSON.parse(decodeURIComponent(localStorage.getItem('selectCarInfo')))
            console.log('carList', this.carList)
            if (this.carList && this.carList.length > 0)
                this.carList.forEach(i => {
                    this.copyCarList.push({
                        refEnterpriseCarId: i.id,
                        carNum: i.num
                    })
                })
        }
        //服务数据
        if (localStorage.getItem('selectServeInfo')) {
            this.serveList = JSON.parse(decodeURIComponent(localStorage.getItem('selectServeInfo')))
            console.log('serveList', this.serveList)
            //接机服务
            let serve1 = this.serveList.findIndex(i => i.serviceName == this.$t('info7'))
            this.theRaising = serve1 != -1 ? 2 : 1
            //儿童椅
            let serve2 = this.serveList.findIndex(i =>i.serviceName == this.$t('info8'))
            let obj2 = {}
            obj2 = this.serveList.find(i => i.serviceName == this.$t('info8') )
            this.theBaby = serve2 != -1 ? obj2.num : 0
            //其他服务
            let arr2 = []
            arr2 = this.serveList.filter(i =>  i.id != null)
            let arr3 = []
            arr2.forEach(i => {
                arr3.push(i.id)
            })
            this.otherServiceIds = arr3.join(',')
        }
    },
    methods: {
        //获取手机区号列表
        getPhoneAreaList() {
            $api.getAreaCodeList().then(res => {
                if (res.code == 200) {
                    this.areaList = res.data || []
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //打开弹窗--负责人
        handlePerson(datas) {
            this.personForm = {
                directorSurname: '',//姓
                directorName: '',//名
                directorEmail: '',//邮箱
                directorMobile: '',//手机号
            }
            if (datas) {
                //编辑
                this.personForm = JSON.parse(JSON.stringify(datas))
                this.personForm.directorMobile = this.areaCode + this.personForm.directorMobile//手机号
            }
            this.isShow = true
        },
        //确认弹窗--负责人
        confirmPerson() {
            this.personInfo = {
                directorSurname: this.personForm.directorSurname,//姓
                directorName: this.personForm.directorName,//名
                directorEmail: this.personForm.directorEmail,//邮箱
                directorMobile: this.personForm.directorMobile,//手机号
            }
            this.isShow = false
        },
        //提交订单
        handleSubmit() {
            if (!localStorage.getItem('token')) {
                this.$message.warning(this.$t('info40'))
                setTimeout(() => {
                    this.$store.commit('SHOWLOGIN')
                }, 1000);
                return
            }
            if (Object.keys(this.personInfo).length == 0) {
                this.$message.warning(this.$t('info38'))
                return
            }
            let dataParams = {}
            let params = {}
            dataParams = {
                orderSource: 'PC',
                ...this.orders,
                theRaising: this.theRaising,//接机服务（1不需要 2需要）
                theBaby: this.theBaby,//儿童座椅数量
                otherServiceIds: this.otherServiceIds,//其他服务

                dmEnterpriseOrderCarBindDtoList: this.copyCarList,//车型列表
                numberOfPassengers: Number(this.orders.numberOfPassengers) || 0,//乘客数
                airplaneNo: this.form.airplaneNo,//航班号
                orderLeaveMessage: this.form.orderMessage,//订单留言
                authorityEmailOrNot: this.form.isEmail,//官方邮件
                payWay: this.form.payWay,//支付方式

                //乘车人
                directorName: this.personInfo.directorName,
                directorSurname: this.personInfo.directorSurname,
                directorEmail: this.personInfo.directorEmail,
                directorMobile: this.areaCode + ' ' + this.personInfo.directorMobile,
            }

            if (this.curOrderType == 0) {//专车
                params = {
                    travelDistance: Number(this.orders.travelDistance),//行程距离
                }
            } else if (this.curOrderType == 1) {//包车
                params = {
                    vehicleDuration: Number(this.orders.vehicleDuration),//租车时长
                }
            }
            Object.assign(dataParams, params)

            console.log('提交', dataParams)
            if (this.form.payWay != 3) {
                //支付宝、微信
                $api[this.curOrderType == 0 ? 'orderGeneration' : 'charteredBusOrderGeneration'](dataParams).then(res => {
                    if (res.code == 200) {
                        this.goPay(res.data);//去支付
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else {
                //信用卡支付
                // let params3 = {}
                // params3 = {
                //     bankName: this.form.bankName,//所属银行
                //     bankCode: this.form.bankCode,//信用卡号
                //     bankSafeCode: this.form.bankSafeCode,//信用卡安全码
                //     bankTime: this.form.bankTime,//信用到期时间
                // }
                this.$message.success(this.$t('N22'))
            }
        },
        //打开扫码支付弹窗
        goPay(orderId) {
            let params = {
                orderId: orderId,
                orderType: 1, //1企业 2用户
            }
            //获取支付信息
            $api[this.form.payWay == 1 ? 'setzfbPay' : 'setWxPay'](params).then(res => {
                if (res.code == 200) {
                    this.codeInfo = res.data || {}
                    this.$refs.payChild.getOpenDialog()
                    this.payDialog = true
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //关闭弹窗--扫码支付
        closePayDialog() {
            this.payDialog = false
        },
    }
}
</script>


<style lang="scss" scoped>
.contain-box {
    padding: 35px 90px 20px 46px;
}

.cover-box {
    padding-left: 20px;
}

.text-box {
    font-size: 18px;
    color: #2E2D2D;
}

.common-title {
    margin: 36px 0 20px;
}

.inputs {
    height: 58px;
}

.card-imgs {
    flex: 0 0 18px;
    margin-right: 10px;
    width: 18px;
    height: 16px;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}

//行程
.cards {
    line-height: 40px;

    &.items {
        margin-right: 40px;
    }

    span {
        flex: 0 0 90px;
        color: #707070;
    }
}

//服务
.serve-box {
    gap: 40px;

    .items {
        row-gap: 20px;
    }
}

//负责人
.person-box {
    .names {
        margin-bottom: 20px;
        font-size: 20px;
        color: #2E2D2D;
        cursor: pointer;
    }

    .items {
        margin-right: 22px;
        font-size: 18px;
        color: #000000;
    }
}

//弹窗
.dialog-con {
    padding: 37px 20px 50px;

    .con-title {
        flex: 0 0 110px;
        line-height: 50px;
        font-size: 20px;
        color: #333;
        text-align: right;
    }

    .f-inputs {
        height: 50px;
    }

    .select-box {
        width: 440px;
        height: 50px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;

        ::v-deep .el-input__inner {
            border: none;
        }
    }
}
</style>